import React, {useState} from 'react';
import {Container, Row, Col, Form, Button}  from 'react-bootstrap';
import '../App.css';
import Footer from '../components/Footer';
import MainNavbar from '../components/MainNavbar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import {useNavigate} from 'react-router-dom';


const Recruiter = () => { 

    const navigate = useNavigate();
    
    const [jbTitle, setjbTitle] = useState("");
    const [compName, setcompName] = useState("");
    const [compAddress, setcompAddress] = useState("");
    const [compContact, setcompContact] = useState("");
    const [compEmail, setcompEmail] = useState("");
    const [description, setDescription] = useState("");
    const [qualification, setQualification] = useState("");
    const [experience, setExperience] = useState("");
    const [region, setRegion] = useState("");
    const [gender, setGender] = useState("");
    const [country, setCountry] = useState("");
    const [salary, setSalary] = useState("");
    const [jobCat, setJobCat] = useState("");
    const [jobType, setJobType] = useState("");
    const [closeDate, setCloseDate] = useState("");

    const createJobPosting = (e) => {
        console.log("SkilH: Recruiter Page");

        const job_posting = {

          job_title: jbTitle,
          company: compName,
          skills: description,
          qualification: qualification,
          experience: experience,
          region: region,
          gender: gender,
          country: country, 
          job_type: jobType, 
          expected_salary: salary,
          job_category: jobCat,
          location: compAddress,
          contact_number: compContact,
          email: compEmail,
          close_date: closeDate

        }

        console.log(job_posting);

        axios.post(`https://skillh-backend.onrender.com/createPosting`, { job_posting })
        .then(res => {
          console.log(res)
          if(res.data.success === true) {
              navigate("/");
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    return <> 
    <div className='App-bg roboto-font'>
    <MainNavbar/>
    <br />
    <p className='section-title'>#Recruiter - Create Your Job Posting!</p>
    <hr />
    <br />
    <Container className='App-bg roboto-font recruiter-box'>
    <Form>
      <Form.Group as={Row} className="mb-3" controlId="jobTitle">
        <Form.Label column sm="2">
          Job Title
        </Form.Label>
        <Col sm="4">
          <Form.Control type="text" value={jbTitle} onChange={(e) => setjbTitle(e.target.value)} placeholder="Job Title" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="company">
        <Form.Label column sm="2">
          Company Name
        </Form.Label>
        <Col sm="4">
          <Form.Control type="text" value={compName} onChange={(e) => setcompName(e.target.value)} placeholder="Company Name" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="address">
        <Form.Label column sm="2">
          Company Address
        </Form.Label>
        <Col sm="4">
          <Form.Control as="textarea" value={compAddress} onChange={(e) => setcompAddress(e.target.value)} rows={3} placeholder="Company Address" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="company">
        <Form.Label column sm="2">
          Company Email
        </Form.Label>
        <Col sm="4">
          <Form.Control type="text" value={compEmail} onChange={(e) => setcompEmail(e.target.value)} placeholder="Company Email Address" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="contact">
        <Form.Label column sm="2">
          Contact Number
        </Form.Label>
        <Col sm="4">
          <Form.Control type="number" value={compContact} onChange={(e) => setcompContact(e.target.value)} placeholder="Contact No" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="skills">
        <Form.Label column sm="2">
          Skills / Job Description
        </Form.Label>
        <Col sm="4">
          <Form.Control as="textarea" value={description} onChange={(e) => setDescription(e.target.value)} rows={3} placeholder="Job Description" />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="Qualification">
        <Form.Label column sm="2">
           Qualification
        </Form.Label>
        <Col sm="4">
        <Form.Select value={qualification} onChange={(e) => setQualification(e.target.value)}>
        <option>Choose Qualification</option>
        <option value="CPE">CPE</option>
        <option value="Form 3">Form 3</option>
        <option value="SC">SC</option>
        <option value="HSC">HSC</option>
        <option value="No Qualification Needed">No Qualification Needed</option>
        </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="experience">
        <Form.Label column sm="2">
            Experience
        </Form.Label>
        <Col sm="4">
        <Form.Select value={experience} onChange={(e) => setExperience(e.target.value)}>
        <option>Choose Experience</option>
        <option value="No experience needed">No experience needed</option>
        <option value="5 years">5 years</option>
        <option value="2 years">2 years</option>
        <option value="More than 10 years">More than 10 years</option>
        </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="Region">
        <Form.Label column sm="2">
            Region
        </Form.Label>
        <Col sm="4">
        <Form.Select value={region} onChange={(e) => setRegion(e.target.value)}>
        <option>Choose Region</option>
        <option value="North">North</option>
        <option value="East">East</option>
        <option value="South">South</option>
        <option value="West">West</option>
        </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="Gender">
        <Form.Label column sm="2">
            Gender
        </Form.Label>
        <Col sm="4">
        <Form.Select value={gender} onChange={(e) => setGender(e.target.value)}>
        <option>Choose Gender</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Any">Any</option>
        </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="country">
        <Form.Label column sm="2">
            Country
        </Form.Label>
        <Col sm="4">
        <Form.Select value={country} onChange={(e) => setCountry(e.target.value)}>
        <option>Choose Country</option>
        <option value="Mauritius">Mauritius</option>
        </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="country">
        <Form.Label column sm="2">
            Job Type
        </Form.Label>
        <Col sm="4">
        <Form.Select value={jobType} onChange={(e) => setJobType(e.target.value)}>
        <option>Choose Type</option>
        <option value="Full Time">Full Time</option>
        <option value="Contract Basis">Contract Basis</option>
        </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="country">
        <Form.Label column sm="2">
            Job Category
        </Form.Label>
        <Col sm="4">
        <Form.Select value={jobCat} onChange={(e) => setJobCat(e.target.value)}>
        <option>Choose Job Category</option>
        <option value="Cashier">Cashier</option>
        <option value="Gardener">Gardener</option>
        <option value="Driver">Driver</option>
        <option value="Cleaner">Cleaner</option>
        <option value="Mechanic">Mechanic</option>
        <option value="Plumber">Plumber</option>
        <option value="Electrician">Electrician</option>
        <option value="Security Agent">Security Agent</option>
        <option value="Pompist">Pompist</option>
        <option value="Cook">Cook</option>
        <option value="Gym Trainer">Gym Trainer</option>
        <option value="Waiter">Waiter</option>
        </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="country">
        <Form.Label column sm="2">
            Expected Salary 
        </Form.Label>
        <Col sm="4">
        <Form.Select value={salary} onChange={(e) => setSalary(e.target.value)}>
        <option>Choose Expected Salary</option>
        <option value="Rs 15000 - Rs 20000">Rs 15000 - Rs 20000</option>
        <option value="Rs 25000 - Rs 30000">Rs 25000 - Rs 30000</option>
        <option value="No disclosure">No disclosure</option>
        </Form.Select>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="deadline">
        <Form.Label column sm="2">
          Closing Date
        </Form.Label>
        <Col sm="4">
          <Form.Control type="date" value={closeDate} onChange={(e) => setCloseDate(e.target.value)} placeholder="Closing Date" />
        </Col>
      </Form.Group>
    </Form>
    <Button size='md' onClick={createJobPosting} className='btn-blue'>Save Job Posting <span><FontAwesomeIcon icon={faSave} /></span></Button><br />
    <br />
    </Container>
    <br />
    <br />
    <Footer/>
    </div>
</>
};

export default Recruiter;
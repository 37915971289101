import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button }  from 'react-bootstrap';
import '../App.css';
import Footer from '../components/Footer';
import RecentJobs from '../components/RecentJobs';
import MainNavbar from '../components/MainNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'




const Home = () => {

    const navigate = useNavigate();

    const navigateTo = () => {
        navigate('/recommend'); // replace with your target page route
    };

    const [words, setWords] = useState(["Mechanic", "Gardener", "Electricien", "Waiter"]); // Your array of words
    const [displayedWord, setDisplayedWord] = useState(words[0]); // Currently displayed word

    useEffect(() => {
        // Function to update displayed word at regular intervals
        const interval = setInterval(() => {
            // Generate a random index to select a word from the array
            const randomIndex = Math.floor(Math.random() * words.length);
            // Update displayed word with the randomly selected word
            setDisplayedWord(words[randomIndex]);
        }, 2000); // Change this value to adjust the speed of the animation

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [words]); // Re-run effect when the words array changes
 
    return <> 
    <div className='App-bg roboto-font'>
    <MainNavbar/>
    <Container className='App-bg roboto-font' >
      <Row>
        <Col md={6}>
            <RecentJobs />
        </Col>
        <Col md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Row>
                <div style={{ display: "flex", justifyContent: "center", fontSize: "35px" }}>
                    <p>Are you a <span style={{ color: "#002B76" }}><strong><u>{displayedWord}</u>?</strong></span></p>
                </div>
                <div className='home-intro'>
                    <p>
                        Welcome to Skilh, where blue-collar career aspirations meet the power of AI!
                        Unleash your potential and discover a world of endless opportunities tailored just for you.
                    </p>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                    <Button size="md" className="btn-blue" onClick={navigateTo}>
                        Let's recommend a Job <span><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                    </Button>
                </div>
            </Row>
        </Col>
      </Row>
    </Container>
    <br />
    <Footer/>
    </div>
</>
};

export default Home;
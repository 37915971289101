import React , { useState, useEffect } from "react";
import { Container, Nav, Navbar }  from 'react-bootstrap';
import Logo from "../assets/logob.png";

let navData = [
    {
        name: "Home",
        url: "/"
    },
    {
        name: "Recruiter",
        url: "/recruiter"
    }, 
    {
        name: "About Us",
        url: "#about"
    },
    {
        name: "Contact Us",
        url: "#contact"
    },  

]

const MainNavbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        const isTop = window.scrollY < 100;
        if (!isTop) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
      <Navbar expand="lg" className={`App-navbar-bg roboto-font ${isScrolled ? 'fixed-top' : ''}`}>
        <Container>
          <Navbar.Brand href="/"><img src={Logo} alt="Logo" className="logo-b" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="me-auto justify-content-between" style={{ width: "70%" }}>
              {
                navData.map((val, index) => (
                  <Nav.Link style={{color: "#F8F8EB", fontSize: "18px"}} key={index} href={val.url}>{val.name}</Nav.Link>
                ))
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };
  
  export default MainNavbar;
import React, {useState, useEffect} from "react";
import { Card, Container, Button, Badge}  from 'react-bootstrap';
import axios from 'axios';

const RecentJobs = () => {

    const [recentJobs, setRecentJobs] = useState([]);

    // Get current date
    const currentDate = new Date();

    useEffect(() => {
        const fetchRecentJobs = async () => {
          try {
            console.log('Fetching recent jobs');
            const response = await axios.get('https://skillh-backend.onrender.com/recentJobs'); // Replace with your API endpoint
            const data = response.data.data;
            console.log(data);
            setRecentJobs(data);
          } catch (error) {
            console.error(error);
            // Handle errors appropriately (e.g., display an error message)
          }
        };
        fetchRecentJobs();
      }, []);

    
    return (
    <Container>
        <br />
        <p className="sub-title">#Recently Added Jobs</p>
        <hr />
        <Container style={{ overflow: "auto", maxHeight: "700px" }}>  
        {recentJobs.length > 0 && recentJobs
        .filter((job) => new Date(job.close_date) >= currentDate)  // Check if there are any jobs before rendering
        .map((job, index) => ( 
        <Card key={job._id}>
            <Card.Header>{job.company}<span style={{ float: "right" }}> <Badge bg="success">Exp: {job.experience}</Badge> <Badge bg="info">Job Type: {job.job_category}</Badge> <Badge bg="warning">Region: {job.region}</Badge></span></Card.Header>
            <Card.Body>
                <Card.Title>{job.job_title}</Card.Title>
                <Card.Text>
                {job.skills}
                </Card.Text>
                <Button variant="primary" className="btn-sm">Click for more info</Button>
                <span style={{ float: "right" }}> <Badge bg="danger">Closing Date: {job.close_date.slice(0, 10)}</Badge> </span>
            </Card.Body>
        </Card>
       ))}
        <br />
        </Container> 
    </Container>
    );
}

export default RecentJobs;
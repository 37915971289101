import React from "react";
import { Card, Container, Button, Badge}  from 'react-bootstrap';

const RecommendJobs = ({ jobData }) => {

    if (!jobData) {
      return <div>No job recommendations available.</div>;
    }

    // Get current date
    const currentDate = new Date();
    
    console.log(jobData)
    return (
      <Container style={{ overflow: "auto", maxHeight: "700px" }}>
        {jobData.map((job, index) => (
              <Card key={index}>
                <Card.Header>
                  {job.company}
                  <span style={{ float: "right" }}>
                    <Badge bg="success">Exp: {job.experience}</Badge>
                    <Badge bg="info">Job Type: {job.job_category}</Badge>
                    <Badge bg="warning">Region: {job.region}</Badge>
                  </span>
                </Card.Header>
                <Card.Body>
                  <Card.Title>{job.job_title}</Card.Title>
                  <Card.Text></Card.Text>
                  <Button variant="primary" className="btn-sm">
                    Click for more info
                  </Button>
                  <span style={{ float: "right" }}>
                    <Badge bg="danger">Closing Date: {job.close_date}</Badge>
                  </span>
                </Card.Body>
              </Card>
            ))}
        <br />
      </Container>
    );
}

export default RecommendJobs;
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Recruiter from "./pages/Recruiter";

import 'bootstrap/dist/css/bootstrap.min.css';
import Recommend from "./pages/Recommend";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/recruiter" element={<Recruiter />}></Route>
        <Route path="/recommend" element={<Recommend />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
import React from 'react';
import { Container, Row, Col}  from 'react-bootstrap';

const Footer = () => (
<footer className="App-navbar-bg text-light py-4" style={{color: "#F8F8EB", fontSize: "18px"}}>
      <Container>
        <Row>
          <Col>
            <p className="text-center">© {new Date().getFullYear()} Kalki. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
);
export default Footer;
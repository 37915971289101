import React, {useState} from 'react';
import { Container, Row, Col, Form, Button}  from 'react-bootstrap';
import '../App.css';
import Footer from '../components/Footer';
import axios from 'axios';
import MainNavbar from '../components/MainNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import RecommendJobs from '../components/RecommendJobs';


const Recommend = () => { 
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(true);
    const [qualification, setQualification] = useState("");
    const [experience, setExperience] = useState("");
    const [region, setRegion] = useState("");
    const [jobCat, setJobCat] = useState("");
    const [recommendJobData, setRecommendJobData] = useState([]);


    const filterJobs = (e) => {
        console.log("SkilH: Recommend Page");
        setLoading(true);

        // const job_criteria = {
     
        //   region: region,
        //   job_category: jobCat
        // }

        // console.log(job_criteria);
        axios.post(`https://ai-jobapp-production.up.railway.app/predict`, { job_category: jobCat, region: region, qualification: qualification, experience: experience })
        //axios.post(`https://skillh-model.onrender.com/predict`, { job_category: jobCat, region: region, qualification: qualification, experience: experience })
        .then(res => {
          console.log(res.data)
          setLoading(false);
          setVisible(false);
          setRecommendJobData(res.data);
        })
        .catch(error => {
          console.log(error)
          setLoading(false);
        })
      //   setTimeout(() => {
      //     setLoading(false);
      //     setVisible(false);
      // }, 18000);

    }

    return <> 
    <div className='App-bg roboto-font'>
    <MainNavbar/>
    <br />
    <p className='section-title'>#OurSearchEngine - Recommends personalized jobs for you!</p>
    <hr />
    <br />
    <Container className='App-bg roboto-font recruiter-box content'>
    <Row>
      <Col md={6}>
      <br />
      <p className="sub-title">#Search your next job!</p>
      <hr />
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="country">
              <Form.Label column md="4">
                  Job Category
              </Form.Label>
              <Col md="6">
              <Form.Select value={jobCat} onChange={(e) => setJobCat(e.target.value)}>
              <option>Choose Job Category</option>
              <option value="Cashier">Cashier</option>
              <option value="Gardener">Gardener</option>
              <option value="Driver">Driver</option>
              <option value="Cleaner">Cleaner</option>
              <option value="Mechanic">Mechanic</option>
              <option value="Plumber">Plumber</option>
              <option value="Electrician">Electrician</option>
              <option value="Security Agent">Security Agent</option>
              <option value="Pompist">Pompist</option>
              <option value="Cook">Cook</option>
              <option value="Gym Trainer">Gym Trainer</option>
              <option value="Waiter">Waiter</option>
              </Form.Select>
              </Col>
          </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="Qualification">
          <Form.Label column md="4">
            Qualification
          </Form.Label>
          <Col md="6">
          <Form.Select value={qualification} onChange={(e) => setQualification(e.target.value)}>
          <option>Choose Qualification</option>
          <option value="CPE">CPE</option>
          <option value="Form 3">Form 3</option>
          <option value="SC">SC</option>
          <option value="HSC">HSC</option>
          <option value="No Qualification Needed">No Qualification Needed</option>
          </Form.Select>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="experience">
          <Form.Label column md="4">
              Experience
          </Form.Label>
          <Col md="6">
          <Form.Select value={experience} onChange={(e) => setExperience(e.target.value)}>
          <option>Choose Experience</option>
          <option value="No experience">No experience</option>
          <option value="5 years">5 years</option>
          <option value="2 years">2 years</option>
          <option value="More than 10 years">More than 10 years</option>
          </Form.Select>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="Region">
          <Form.Label column md="4">
              Region
          </Form.Label>
          <Col md="6">
          <Form.Select value={region} onChange={(e) => setRegion(e.target.value)}>
          <option>Choose Region</option>
          <option value="North">North</option>
          <option value="East">East</option>
          <option value="South">South</option>
          <option value="West">West</option>
          </Form.Select>
          </Col>
        </Form.Group>
      </Form>
      <Button size='md' onClick={filterJobs} className='btn-blue'>
      {loading ? (
                    <span>Please wait. Searching for Jobs...</span>
                ) : (
                    <>
                        Find Jobs <span><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                    </>
                )}
      </Button><br />
      <br />
      </Col>
     
      <Col md={6}>
          {!visible &&  <RecommendJobs jobData={recommendJobData}  /> }
      </Col>
      </Row>
    </Container>
    <br />
    <br />
    <Footer/>
    </div>
</>
};

export default Recommend;